import React from 'react';
import './style.css'
import bg2 from '../../../../assets/bg2.svg'
const PolicyPolicy = () => {
  return (
    <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',backgroundColor:'#F3F8FD',backgroundPositionY:'100%'}}>
  
  
    <div className="policy-page" >
      <h1 className="policy-page__title">Privacy <div className='highlight'>Policy</div></h1>
      <p className="policy-page__description" >
brigosha Technologies Pvt Ltd. maintains the website <a href='https://brigosha.com/'>www.brigosha.com</a>, which
offers the SERVICE. This page is intended to inform visitors about our policies
regarding the collection, use, and disclosure of Personal Information if they
choose to use our Service. If you use our Service, you agree to the collection and
use of information in accordance with this policy. We gather Personal Information
in order to provide and improve the Service. Except as specified in this Privacy
Policy,we will not use or disclose your information with anybody. Unless
otherwise stated in this Privacy Policy, the terminology used in this Privacy Policy
have the same meanings as those in our Terms and Conditions, which may be
found at <a href='https://brigosha.com/'>www.brigosha.com </a>

Data Gathering and Use. When visitors post comments on
the site, we may ask you for personally identifiable information such as your
name, phone number, and postal address in order to provide you with a better
experience. The information we gather will be used to contact or identify
you.</p>
<p className="policy-page__heading">Security</p>
<p className="policy-page__description"> 
We respect your trust in giving us with your Personal Information, thus we strive
to secure it using commercially reasonable ways. Contact Us
You can request an exported file of the personal data we possess on you,
including any data you have supplied to us, if you have an account on our site or
have posted comments. You can also request that we erase any personal data
we hold about you. This excludes any data that we are required to keep for
administrative,legal or security reasons. If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
      </p>
     
   
    </div>
    </div>
  );
};

export default PolicyPolicy;
