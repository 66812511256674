import { Col, Row, Button, Menu, Dropdown } from 'antd';
import { FooterContents } from './style';
import { Link ,useLocation} from 'react-router-dom';
import {ReactComponent as CompanyLogo} from './brigosha-logo-new.svg';
import { YoutubeFilled, TwitterSquareFilled, LinkedinFilled} from '@ant-design/icons'
import { connect } from 'react-redux';
import { updateMainMenu } from '../PrimaryNavbar/redux/action';
import { useEffect } from 'react';
import { useState } from 'react';

import {ReactComponent as Youtube} from './youtube-logo.svg'
import {ReactComponent as Twitter} from './twitter-logo.svg'
import {ReactComponent as LinkedIn} from './linkedIn-logo.svg'


const SubMenu = {
    'we': ['Our Story', 'Teams', 'Join Us', 'Get In Touch'],
    'Technology': ['Mobility Solutions', 'Connected Solutions','Lidar Community'],
    'Products':['Your Company App',"BOTG","Off Highway Power Train Remote Diagnostics"]
    
}
const Footer = ({ pathName, updateMainMenu }) => {

    const scrollToTop = () => {
        const elements = document.querySelectorAll('.hover-active');
        elements.forEach((element) => {
          element.classList.remove('hover-active');
        });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.scrollTo(0, 0);
      
    }
    const [endpoint,setEndPoint]=useState();
  
    let location = useLocation();
  
    useEffect(()=>{
        const currentURL = window.location.href;
        const url = currentURL.substring(currentURL.lastIndexOf('/') + 1);
        setEndPoint(url)
      
         
    console.log('url :',window.location.href)
    },[location,window.location.href])

    useEffect(() => {
        const handleResize = () => {
          
          document.getElementById('footer').style.backgroundSize= `${window.innerWidth>1000?200:window.innerWidth>560?600:1200}%`;
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


  
    return (
            <FooterContents className='content' id='footer'>
                <div
                className='footer-footer-links'
                gutter = {30}
                style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap',marginBottom:'20px'}}
         
                >
                    <div   >
                    <Col 
                      className='footer-company-info'
                    >
                        <Link 
                        to = '/'
                        onClick={() => {
                            scrollToTop()
                            updateMainMenu('we')
                        }}
                        >
                            {/* <img 
                            src = {CompanyLogo} 
                            alt = 'brigosha-Logo'
                            className='footer-company-logo'
                            /> */}
                            <CompanyLogo   className='footer-company-logo' style={{fill:'#0086FF'}}/>
                        </Link>
                       
                     <div className='associate-link'>  
                        
                     <h3 style={{fontWeight: 600,color:"rgba(38, 50, 56, 1)"}} >Inside brigosha</h3>
                        <a href = 'https://newpmt.brigosha.com' target="_blank" rel="noopener noreferrer">
                            <Button type="default" className='footer-sign-in primary'>
                                SIGN IN 
                            </Button>
                        </a>
                     </div>
                       
                    </Col>
                    </div>
                    <div 
                    className='footer-links-wrapper'
                    >

                    
                    <Col 
                      style={{width:'20%',padding:5}}
                        className='weLinks'
                    >
                        <Link 
                        to = '/'
                        className='hoverEffect'
                        onClick={() => {
                            scrollToTop()
                            updateMainMenu('we')
                        }}
                        >
                        <h3 style={{fontWeight: 600}} className={`footer-heading ${endpoint==''?'hover-active':''}`}>We</h3>
                        </Link>
                        <ul className='footer-list'>
                            {
                                SubMenu['we'].map((elem,index) => (
                                    <li key={elem}>
                                        <Link 
                                        to={elem === 'Our Story' ? '/' : `/${elem.toLowerCase().split(' ').join('-')}`} 
                                        
                                        className={`footer-links grey ${endpoint==elem.toLowerCase().split(' ').join('-')||index==0&&endpoint==''|| window.location.href.toString().includes(elem.toLowerCase().split(' ').join('-'))?'hover-active':''}`}
                                        onClick={() => {
                                            scrollToTop()
                                            updateMainMenu('we')
                                        }} 
                                        >
                                            {elem}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col>
                    <Col 
                    style={{width:'27%',padding:5}}
                        className='weLinks'
                    >
                        <Link 
                        to='/technology'
                        onClick={() => {
                            scrollToTop()
                            updateMainMenu('technology')
                        }}
                        >
                            <h3 style={{fontWeight: 600}} className={`footer-heading ${endpoint=='technology'?'hover-active':''}`} >Technology</h3>
                        </Link>
                        <ul className='footer-list'>
                            {
                                SubMenu['Technology'].map((elem,index) => (
                                 
                                    <li key={elem}>

                                        <Link 
                                        to={elem === 'Mobility Solutions' ? '/technology':elem === 'Lidar Community'?"": `/technology/${elem.toLowerCase().split(' ').join('-')}`} 
                                    
                                        className={`footer-links grey ${endpoint==elem.toLowerCase().split(' ').join('-')||index==0 && endpoint=='technology'|| 
                                        window.location.href.includes(elem.toLowerCase().split(' ').join('-'))?'hover-active':''}`} 
                                        onClick={() => {
                                            if( elem === 'Lidar Community'){
                                                window.open('https://testlidar.brigosha.com', '_blank');
                                            }else{
                                            scrollToTop()
                                            updateMainMenu('technology')
                                            }
                                        }}
                                        >
                                            {elem}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col>
                    {/* <Col 
                      style={{width:'26%'}}
                        className='weLinks'
                    >
                        <Link 
                        to='/technology/connected-solutions/YCA'
                        onClick={() => {
                            scrollToTop()
                            updateMainMenu('technology')
                        }}
                        >
                            <h3 style={{fontWeight: 600}} className={`footer-heading ${endpoint=='technology/connected-solutions/YCA'||endpoint=='technology/connected-solutions/BOTG'?'hover-active':''}`} >Products</h3>
                        </Link>
                        <ul className='footer-list'>
                            {
                                SubMenu['Products'].map((elem,index) => (
                                 
                                    <li key={elem}>

                                        <Link 
                                        to={elem === 'Your Company App' ? '/technology/connected-solutions/YCA' :elem==='BOTG'?'/technology/connected-solutions/BOTG':'/technology/connected-solutions/Off-Highway-Power-Train-Remote-Diagnostics'} 
                                    
                                        className={`footer-links grey ${ window.location.href.includes(elem === 'Your Company App'?"YCA":elem==="BOTG"?'BOTG':'Off-Highway-Power-Train-Remote-Diagnostics')?'hover-active':''}`} 
                                        onClick={() => {
                                            scrollToTop()
                                            updateMainMenu('technology')
                                        }}
                                        >
                                            {elem}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Col> */}
                 
                    <Col 
                      style={{width:'20%'}}
                      className='weLinks'
                    >
                        <h3 style={{fontWeight: 600,color:"rgba(38, 50, 56, 1)"}} >Contact Us</h3>
                        <p style={{listStyle:'none'}}>brigosha HQ · 1023, Siri Shubham, 24th Main Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102, India
                        </p>
                       

                        <br/><br/>
                      
                    </Col>
                    </div>
                  <div style={{width:'200px'}}>
                    <Col 
                  style={{width:'100%'}}
                    className='footer-company-info associate-link-info'
                    >
                     
                        <h3 style={{fontWeight: 600,color:"rgba(38, 50, 56, 1)"}} >Inside brigosha</h3>
                        <a href = 'https://newpmt.brigosha.com' target="_blank" rel="noopener noreferrer">
                            <Button type="default" className='footer-sign-in primary'>
                                SIGN IN 
                            </Button>
                        </a>

                       
                    </Col>
                    </div>
                </div>
                <Col  className='copy-right-top' ><div >Copyright © 2023, brigosha technologies</div></Col>
                <Row className='footer-footer'>
                 
                 
                    <div className='footer-end'>
                        <div className='copy-right'>
                 
                        <Col style={{borderRight:'1.5px solid rgba(38, 50, 56, 1)',paddingRight:'10px',display:'flex',alignSelf:'center'}} className='copy-right-text'><div >Copyright © 2023, brigosha technologies</div></Col>
                        <Col style={{borderRight:'1.5px solid rgba(38, 50, 56, 1)',paddingRight:'10px',paddingLeft:'10px',display:'flex',alignSelf:'center'}}><Link to={'/our-story/privacy-policy'}    className={`footer-links grey ${endpoint=='privacy-policy'?'hover-active':''}`}   onClick={() => {
                                            scrollToTop()
                                        
                                        }}>Privacy</Link></Col>
                        <Col style={{paddingRight:'10px',paddingLeft:'10px',display:'flex',alignSelf:'center'}}><Link to={'/our-story/terms-conditions'} className={`footer-links grey ${endpoint=='terms-conditions'?'hover-active':''}`}  onClick={() => {
                                            scrollToTop()
                                        
                                        }}>Terms & Conditions</Link></Col>
                 
                                        </div>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px',padding:8}}>
                                  
                            <Col style={{paddingRight:'5px',paddingLeft:'5px',width:'30px',height:'30px',border:'1.3px solid rgba(38, 50, 56, 1)',borderRadius:'100px'}}> 
                            <a href = 'https://twitter.com/Brigosha' target="_blank" rel="noopener noreferrer" style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <Twitter style = {{ fontSize: 'calc(1vw + 25px)'}}/>
                                </a></Col>
                           
                                <Col style={{paddingRight:'5px',paddingLeft:'5px',width:'30px',height:'30px',border:'1.3px solid rgba(38, 50, 56, 1)',borderRadius:'100px'}}>   
                                <a href = 'https://www.linkedin.com/company/brigosha/' target="_blank" rel="noopener noreferrer" style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <LinkedIn style = {{ fontSize: 'calc(1vw + 25px)'}}/>
                                </a></Col>
                                <Col style={{paddingRight:'5px',paddingLeft:'5px',width:'30px',height:'30px',border:'1.3px solid rgba(38, 50, 56, 1)',borderRadius:'100px'}}>
                                      <a href = 'https://www.youtube.com/@BRIGOSHA' target="_blank" rel="noopener noreferrer" style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <Youtube style = {{ fontSize: 'calc(1vw + 25px)'}} />    
                                </a></Col>
                        
                        {/* </ul> */}
                        </div>
                    </div>
                  
                 
                </Row>
            </FooterContents>
    )
}

const menu = (
    <Menu>
        <Menu.Item key="0">
            Hindi
        </Menu.Item>
        <Menu.Item key="1">
            Assamese
        </Menu.Item>
        <Menu.Item key="3">
            Kannada   
        </Menu.Item>
    </Menu>
  );


const mapStateToProps = state => {
    const {
        pathName
    }  = state.menu;

    return {pathName}
}

const mapDispatchToProps =dispatch =>  ({
    updateMainMenu: (val) => dispatch(updateMainMenu(val))
})




export default connect(mapStateToProps, mapDispatchToProps)(Footer);

