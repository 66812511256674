import React from 'react'
import { GoLocation } from 'react-icons/go';
import { getJob } from '../../redux/action';
import { connect } from 'react-redux';
import { ApplyButton, JdCardBody, JdCardHeading ,  PerksText,PerksBody, JdAddress} from './styles';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import styled from 'styled-components';
import parse from 'html-react-parser';
import './styles.css'
import { Modal  } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import NotFound from '../../../technology/pagenotfound';
import { LoadingOutlined } from '@ant-design/icons';
import {ReactComponent as Arrow} from '../ourStory/PotentialCard/Vector.svg'
import bg2 from '../ourStory/ourCulture/BG.svg'
import { ReactComponent as MapPin } from './icons/map-icon.svg';
import { ReactComponent as Clock } from './icons/time-icon.svg';
import { ReactComponent as Line } from './icons/line-icon.svg';
import { ReactComponent as ExpIcon } from './icons/exp-icon.svg';
import { ReactComponent as Share } from './icons/share-icon.svg';
import { ReactComponent as LogoIcon } from './icons/brigosha-logo-new.svg';
import { ReactComponent as Back } from '../../../../assets/back-icon.svg';
import { Document, Page, pdfjs } from 'react-pdf';

// Set worker path for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const axios = require('axios');


 function JobDescription({getJob,currentJob}) {
    const navigate = useNavigate();
    const params=useParams();
    const [loader,setLoader]=useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageHeight, setPageHeight] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
  
    let canavs= document.getElementsByClassName('react-pdf__Page__canvas');

      pdfjs.getDocument("https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/description/e91badf4-b8b1-43ab-b81f-20b3a8ec0cd7.pdf").promise.then((pdf) => {
        pdf.getPage(1).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          setPageHeight(viewport.height);
        });
      });
    }


    useEffect(()=>{
      document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])
    const handleBackBtn=()=>{
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      window.history.back();
     
  }
    useEffect(()=>{

    
   let param={
    jobId:params?.jobId?.split('=')[1]
   }

      if(!currentJob||currentJob.length==0){
        setLoader(true);
        }
        getJob(param,() => setLoader(false))
        return () => {   
        };
    },[])

    const handleCancel = () => {
      setIsModalOpen(false);
   
    };
    const copyLink=()=> {
      var copyText = document.getElementById("jd-link");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      
      var tooltip = document.getElementById("jd-Tooltip");
      tooltip.innerHTML = "Copied: " + copyText.value;
    }
    
    const removeLink=()=>  {
      var tooltip = document.getElementById("jd-Tooltip");
      tooltip.innerHTML = "Copy to clipboard";
    } 

  return (
    <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
  
      }}>
        
   { loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
  
           <LoadingOutlined style={{fontSize: "3em"}}/> 
       
    </div>:
    currentJob==undefined  ?<NotFound/>:
    currentJob?.jobDetailes?.length==0?<div style={{padding: '20px 8vw',minHeight:'100vh',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}> 
    <h2>
    Job Description Not Found!!!
    </h2>

    </div>:
//     <div style={{padding: '20px 8vw',minHeight:'70vh',display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}} className='jd-wrapper'>
//      <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px',width:'100%',maxWidth:'100vw'}}>
//            <Back className='back-btn' onClick={handleBackBtn} />
//            </div>
    
//     {/* <JdCardHeading className='heading highlight ' style={{maxWidth:'900px',paddingTop:0,textAlign:'left'}}>
//         {currentJob?.jobDetailes?.[0]?.details}
//     </JdCardHeading> */}
//     <PerksBody className=' heading highlight jd-heading-top'  style={{'textAlign':'left',maxWidth:'900px',fontWeight:700,width:'100%'}}>  {currentJob?.jobDetailes?.[0]?.details}</PerksBody>
//     {/* <JdAddress className='content grey'   >
//      <div>
//             <GoLocation style={{marginRight: 10}}/> </div>
//             <span style={{'max-width':'500px'}}>{currentJob?.jobDetailes?.[1]?.details?.toString()}</span>
//     </JdAddress > */}

//     <PerksBody className='content grey jd-desc ' style={{'textAlign':'left',maxWidth:'900px',fontSize:'16px',width:'100%'}}>
//     <GoLocation style={{marginRight: 10}}/>
//             <span style={{'max-width':'500px'}}>{currentJob?.jobDetailes?.[1]?.details?.toString()}</span>
//       </PerksBody>
//     <br/>
//     {/* <div>
//   <ApplyButton 
//   style={{marginTop: 40}} 
//   onClick={() => {
//     document.body.scrollTop = 0; // For Safari
//     document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
//     navigate(`/join-us/application-form/jobId=${currentJob.id}`)
//   }}
//   >
//     Apply Now
//   </ApplyButton>
// </div> */}
// {
//   currentJob?.jobDetailes?.map((ele,key)=>{
//     if(key==0 || key==1){
//       return null;
//     }else{
//       return <>
//       <PerksBody className='darkblue content '  style={{'textAlign':'left',maxWidth:'900px',fontWeight:700,width:'100%'}}>{ele.title}</PerksBody>
//     <PerksBody className='content grey jd-desc ' style={{'textAlign':'left',maxWidth:'900px',width:'100%'}}>
     
//       { ele?.details?.length>1?
//       <ul style={{paddingLeft:'20px'}}>
//       {ele?.details?.filter(ele=>{
//       if(ele.trim()!=='')
//       return ele;
//       }).map(ele=>{
//         return <li>{ele}</li>
//       })} </ul>:
    
//       <p>{ele?.details} </p>
//     }
       
//      </PerksBody>
//       </>
//     }


   

//   })
// }
//     {/* <PerksText className='darkblue content'>about the role</PerksText>
//     <PerksBody className='content grey jd-desc ' style={{'textAlign':'left',maxWidth:'900px'}}>
//       <p>
//     Facebook Reality Labs focuses on delivering Facebook's vision through Virtual Reality (VR) and Augmented Reality (AR). Facebook Silicon team is driving the state-of-the-art forward with breakthrough work in computer vision, machine learning, mixed reality, graphics, displays, sensors, and new ways to map the human body. Our chips will enable AR & VR devices where our real and virtual world will mix and match throughout the day. We believe the only way to achieve our goals is to look at the entire stack, from transistors, through architecture, to firmware and algorithms.
    
   
//     </p>
//     <p> As a Silicon Automation Engineer at Facebook Reality Labs (FRL), you will use your software development experience to design, implement, and support front end applications used by many internal Facebook and external partner IP teams.</p>
    
//      </PerksBody>

//     <PerksText className='darkblue content'>responsibilities</PerksText>
//     <PerksBody className='content grey' style={{'textAlign':'left',maxWidth:'900px'}}>
//       <ul style={{'marginLeft':'18px'}}>
//         <li> Play a significant role in the early software development of a new front end application built with the latest web technologies, to be used by other groups in their ASIC design and verification efforts</li>
//    <li>Provide software development support for a more mature front end application currently being heavily used by other groups for ASIC design and verification</li>
//    <li>Interface with tool users in other groups to achieve tasks ranging from the collection of requirements to addressing bugs, and everything in between, for these front end tools</li>
//       </ul>
//  </PerksBody>
//  <PerksText className='darkblue content'>minimum qualifications</PerksText>
//     <PerksBody className='content grey' style={{'textAlign':'left',maxWidth:'900px'}}>
//       <ul style={{'marginLeft':'18px'}}>
//         <li>  2+ years of experience as a software design engineer</li>
//    <li>Firm grasp of object oriented programming principles and software design patterns</li>
//    <li>Proficiency in JavaScript (TypeScript), Java, and Python</li>
//    <li>Experience in web technologies such as React and Node.js </li>
//    <li>BS Computer Science/Electrical Engineering or equivalent experience </li>

//       </ul>
//  </PerksBody>
//  <PerksText className='darkblue content'>preferred qualifications</PerksText>
//     <PerksBody className='content grey' style={{'textAlign':'left',maxWidthh:'900px'}}>
//       <ul style={{'marginLeft':'18px'}}>
//         <li> 5+ years of experience with web technologies such as JavaScript (TypeScript), React, and Node.js</li>
//    <li>Experience using the Eclipse Rich Client Platform</li>
//    <li>Industry experience developing electronic design automation (EDA) tooling</li>
//       </ul>
//  </PerksBody> */}
//  <PerksBody className='content grey jd-desc' style={{maxWidth:'900px',fontSize:'16px',display:'flex',justifyContent:'flex-start',width:'100%'}}>
//   <ApplyButton 
//   style={{marginTop: 40,alignSelf:'flex-start',minWidth:'150px'}} 
//   onClick={() => {
//     document.body.scrollTop = 0; // For Safari
//     document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
//     navigate(`/join-us/application-form/jobId=${currentJob.jobId}`)
//   }}
//   >
//     Apply Now <Arrow></Arrow>
//   </ApplyButton>


//   </PerksBody>
//   </div>
<div  style={{padding: '20px 8vw',minHeight:'70vh',display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}>
<div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px',width:'100%',maxWidth:'100vw'}} className='jd-wrapper'>
          <Back className='back-btn' onClick={handleBackBtn} />
          </div>
<div className='jd-description-head'> 
  <div className='jd-logo'>
<LogoIcon style={{width:50,height:50}}/>
  </div>
<Link to={`/join-us/application-form/jobId=${currentJob.jobId}`}>
  <div className='jd-apply-btn'>
   Apply
  </div>
  </Link>

</div>
<div className='jd-description-card'>
  <span className='jd-title ' >{currentJob?.title}</span>
  <div className='jd-meta-row'> 
    <span className='jd-meta-tag'> <ExpIcon/>{currentJob?.workExperience}</span>
    <span className='jd-meta-tag'><Clock/>Full Time</span>
    <span className='jd-meta-tag'><MapPin/>{currentJob?.location?.map(location=>location.name)?.join(', ').toString()}{currentJob?.location?.length>2?'...':''}</span>
    {/* <a href ={ `https://brigosha.com/join-us/job-description/jobId=${currentJob?.jobId}` } target="_blank" > */}
      <span className='jd-share' onClick={()=>{
      setIsModalOpen(true);
    }}><Share/><span style={{textDecoration:'underline'}}>Share this Job</span></span>
    {/* </a> */}
  </div>
  <div className='jd-pdf' style={{height:`auto`,margin:'0 auto',minHeight:'300px'}}>
  {/* <object width="100%" height="100%" data="https://brigosha-hackathon-test-brigosha-5777.s3.ap-south-1.amazonaws.com/description/e91badf4-b8b1-43ab-b81f-20b3a8ec0cd7.pdf" type="application/pdf">   </object> */}
 
      <Document file={currentJob?.file} onLoadSuccess={onDocumentLoadSuccess} width="100%" height="100%" >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>

  </div>
</div>
</div >
 }

<Modal  visible={isModalOpen} footer={null} closable={false} centered onCancel={handleCancel} style={{'zIndex':'100'}}   bodyStyle={{
        backgroundColor: '#FFF'
    }}  destroyOnClose={true} >
         
         <ModelDiv>
            <div   style={{ backgroundColor: '#FFF',}} >
         <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px',}}>
           <div className='event-head' >
             
       

            <span className='jd-title ' >{currentJob?.title}</span>
  <div className='jd-meta-row'> 
    <span className='jd-meta-tag'> <ExpIcon/>{currentJob?.workExperience}</span>
    <span className='jd-meta-tag'><Clock/>Full Time</span>
  

  </div>
          

            </div>
           </div>
       
          </div>
    
        
          
        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',background:'#FFF',border:'2px solid rgba(238, 238, 238, 1)'}}>
                        
      

                             <input style={{'color':'#002546',border:'none',width:'80%',padding:'3px', outline: 'none'}} id='jd-link'
                              value={`https://new.brigosha.com/join-us/job-description/jobId=${currentJob?.jobId}`} />
                           
                            <div class="tooltip">
<button onClick={copyLink} onMouseOut={removeLink} style={{'color':'#fff',border:'none',fontWeight:'700',cursor:'pointer',background:'rgba(0, 134, 255, 1)',padding:'4px'}}>
  <span class="tooltiptext" id="jd-Tooltip" >Copy to clipboard</span>
  Copy Link
  </button>
</div>
                        </div>
                
         </ModelDiv>
   </Modal>   
  </div>
     
  )
}


const mapStateToProps = state => {
    const {
      
     currentJob
    }  = state.we;

    return {currentJob}
}

const mapDispatchToProps = dispatch =>  ({
  getJob: (params,successCallback) => dispatch(getJob(params,successCallback)),
})


export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);

const ModelDiv = styled.div`
max-height:722px;

max-width:943px;


background:#FFF;

.title{
 height:40px;
 font-size: 14px;
 font-weight: 600;

 margin-bottom:10px !important;

 overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 1;
 -webkit-box-orient: vertical;
}

.blog-tags::-webkit-scrollbar {
 height: 5px !important; /* width of the entire scrollbar */
 width:3px !important;
}
.blog-tags::-webkit-scrollbar-thumb {
 background-color: grey; /* color of the scroll thumb */
 border-radius: 20px; /*roundness of the scroll thumb*/
 /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
}

.tooltiptext{
  height:100%;
  left:4px;
}
 
@media  screen and (max-width: 950px){


 max-height:max-content;

 

}
`