import { LoadingOutlined, SearchOutlined ,UserOutlined } from '@ant-design/icons';
import { Col, Input, Row,Modal } from 'antd';
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../../components/pagination';
import styled from 'styled-components';
import { Heading, AllJobsText, JobSearch } from './styles';
import NoBlog from './noBlogs.gif'
import './styles.css'
import bg2 from '../../ourStory/ourCulture/BG.svg'
import eye from './eye.svg'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import Api from '../../../../../services/Api';
import pen from './pen.svg'
import linkedIn from './linkedIn.svg'
import twitter from './twitter.svg'
import shareImg from './share.svg'
import { fetchBlogs } from '../../../redux/action';
import BrigoshaLogo from '../../../../../assets/logo.svg'
function AllBlogs({fetchBlogs, allBlogs}) {

    const navigate = useNavigate();
    const [pageLoader, setPageLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [currentPage,setCurrentPage]=useState(1);
//  const [allBlogs,setAllBlogs]=useState();
   const [share,setShare]=useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
       
        setCurrentPage(1);
    }, []);

   useEffect(()=>{
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
   
   
      let param={
        search:search,
        page:currentPage,
        limit:24
       }
       if(!allBlogs||allBlogs.length==0){
        setPageLoader(true);
       }
               
          
            fetchBlogs(param,()=>{
             
              setPageLoader(false);
            })
      
              return () => {
                  
              };
   },[search,currentPage])
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        navigate('/our-story/blogs')
    }
    const handleSearch = (val) => {
        setSearch(val);
        if(val.length >= 1){
            setCurrentPage(1)
            setPageLoader(true);
         
        }else if(val.length === 0){
            setCurrentPage(1)
            setPageLoader(true);
          
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setShare('');
      };
    
    // const Obj1 =[
    //     {
    //         key:1,
    //         name:'The Impact of Technology on the Workplace: How Technology is Changing asdjs asjkdandhj jknjknijb kdsjafjeh dsjvqwbnas wqbhjf nacs qhbwfj bansmfbqej asnbh',
    //         tag:'Technology',
    //         img:'https://s3-alpha-sig.figma.com/img/d420/949f/d83fa0b11500655074c36b4845f6b20c?Expires=1683504000&Signature=W7dx-Mu-tki5YAupOpm4UuJzfvC6Z872JuNCPuPU8P8JN-JbRVLjsRHHRfaIUhdHCiJxugZONS72zglp0q-L2gFV~Of04D9dzf1UGwgP5Rw9Fm0WeaeH9mgdlh~rKUo~8x6iGbOKjRnxwA3MOsRmP6Sr5A8p7La7lgbdtHU~1CprgMb1mepa78kdQSsAU49n08JfL-mzs68b8pyMMJvxuTevYSGR5BLN7FLnp6HIwANdZWncy5haRgLz0CqPpxPKtWp7My~G1i5bhtu9LsYTon2GjD9ZaFOtCpUeDS-HeGoGKwxeEq6tmM-2eTnPxUYOAhP9M5rA35ohNKaqRAhJDw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         pic:'https://s3-alpha-sig.figma.com/img/40b7/c131/faedfd7f834c47ba118f3895ffc519cd?Expires=1683504000&Signature=eCGpM-WN~39zNM5~kkTLfWtQSEtLN-iogR--jPpkQNbiDoBnZ694f8X9XWo9aoXcRU3kUBH8oCaEv02KQKMM1a12PcFbUOP4rtFY8xFTX5~E3zJ~Rsf7Y21N7xWRBkWepWIPw-93bQJ2KDW0wTBaC8r60i4qyRPg0RkKQXKf1mbLu-GCY65atZY7yKQDdC0Wzc~vd2lIe00D4iWLO0E6EpIcGg1roZBNzWL5onok1f7QlwFJ-0cRV8~5S1QRqeLVFJrPZLfTTNh9MNWmMFn18nPxeOd0lO0cYGswDkiBgVDK5nsXvsynq4Om3YI60xqU7~7juNQn6jSph1hG~ssI5A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         date:'August 20, 2022',
    //         user:'Praveen kumar'
       
       
    //     },
    //     {
    //         key:1,
    //         name:'The Impact of Technology on the Workplace: How Technology is Changing asdjs asjkdandhj jknjknijb kdsjafjeh dsjvqwbnas wqbhjf nacs qhbwfj bansmfbqej asnbh',
    //         tag:'Technology',
    //         img:'https://s3-alpha-sig.figma.com/img/d420/949f/d83fa0b11500655074c36b4845f6b20c?Expires=1683504000&Signature=W7dx-Mu-tki5YAupOpm4UuJzfvC6Z872JuNCPuPU8P8JN-JbRVLjsRHHRfaIUhdHCiJxugZONS72zglp0q-L2gFV~Of04D9dzf1UGwgP5Rw9Fm0WeaeH9mgdlh~rKUo~8x6iGbOKjRnxwA3MOsRmP6Sr5A8p7La7lgbdtHU~1CprgMb1mepa78kdQSsAU49n08JfL-mzs68b8pyMMJvxuTevYSGR5BLN7FLnp6HIwANdZWncy5haRgLz0CqPpxPKtWp7My~G1i5bhtu9LsYTon2GjD9ZaFOtCpUeDS-HeGoGKwxeEq6tmM-2eTnPxUYOAhP9M5rA35ohNKaqRAhJDw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         pic:'https://s3-alpha-sig.figma.com/img/40b7/c131/faedfd7f834c47ba118f3895ffc519cd?Expires=1683504000&Signature=eCGpM-WN~39zNM5~kkTLfWtQSEtLN-iogR--jPpkQNbiDoBnZ694f8X9XWo9aoXcRU3kUBH8oCaEv02KQKMM1a12PcFbUOP4rtFY8xFTX5~E3zJ~Rsf7Y21N7xWRBkWepWIPw-93bQJ2KDW0wTBaC8r60i4qyRPg0RkKQXKf1mbLu-GCY65atZY7yKQDdC0Wzc~vd2lIe00D4iWLO0E6EpIcGg1roZBNzWL5onok1f7QlwFJ-0cRV8~5S1QRqeLVFJrPZLfTTNh9MNWmMFn18nPxeOd0lO0cYGswDkiBgVDK5nsXvsynq4Om3YI60xqU7~7juNQn6jSph1hG~ssI5A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         date:'August 20, 2022',
    //         user:'Praveen kumar'
       
       
    //     },
    //     {
    //         key:1,
    //         name:'The Impact of Technology on the Workplace: How Technology is Changing asdjs asjkdandhj jknjknijb kdsjafjeh dsjvqwbnas wqbhjf nacs qhbwfj bansmfbqej asnbh',
    //         tag:'Technology',
    //         img:'https://s3-alpha-sig.figma.com/img/d420/949f/d83fa0b11500655074c36b4845f6b20c?Expires=1683504000&Signature=W7dx-Mu-tki5YAupOpm4UuJzfvC6Z872JuNCPuPU8P8JN-JbRVLjsRHHRfaIUhdHCiJxugZONS72zglp0q-L2gFV~Of04D9dzf1UGwgP5Rw9Fm0WeaeH9mgdlh~rKUo~8x6iGbOKjRnxwA3MOsRmP6Sr5A8p7La7lgbdtHU~1CprgMb1mepa78kdQSsAU49n08JfL-mzs68b8pyMMJvxuTevYSGR5BLN7FLnp6HIwANdZWncy5haRgLz0CqPpxPKtWp7My~G1i5bhtu9LsYTon2GjD9ZaFOtCpUeDS-HeGoGKwxeEq6tmM-2eTnPxUYOAhP9M5rA35ohNKaqRAhJDw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         pic:'https://s3-alpha-sig.figma.com/img/40b7/c131/faedfd7f834c47ba118f3895ffc519cd?Expires=1683504000&Signature=eCGpM-WN~39zNM5~kkTLfWtQSEtLN-iogR--jPpkQNbiDoBnZ694f8X9XWo9aoXcRU3kUBH8oCaEv02KQKMM1a12PcFbUOP4rtFY8xFTX5~E3zJ~Rsf7Y21N7xWRBkWepWIPw-93bQJ2KDW0wTBaC8r60i4qyRPg0RkKQXKf1mbLu-GCY65atZY7yKQDdC0Wzc~vd2lIe00D4iWLO0E6EpIcGg1roZBNzWL5onok1f7QlwFJ-0cRV8~5S1QRqeLVFJrPZLfTTNh9MNWmMFn18nPxeOd0lO0cYGswDkiBgVDK5nsXvsynq4Om3YI60xqU7~7juNQn6jSph1hG~ssI5A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         date:'August 20, 2022',
    //         user:'Praveen kumar'
       
       
    //     },
    //     {
    //         key:1,
    //         name:'The Impact of Technology on the Workplace: How Technology is Changing asdjs asjkdandhj jknjknijb kdsjafjeh dsjvqwbnas wqbhjf nacs qhbwfj bansmfbqej asnbh',
    //         tag:'Technology',
    //         img:'https://s3-alpha-sig.figma.com/img/d420/949f/d83fa0b11500655074c36b4845f6b20c?Expires=1683504000&Signature=W7dx-Mu-tki5YAupOpm4UuJzfvC6Z872JuNCPuPU8P8JN-JbRVLjsRHHRfaIUhdHCiJxugZONS72zglp0q-L2gFV~Of04D9dzf1UGwgP5Rw9Fm0WeaeH9mgdlh~rKUo~8x6iGbOKjRnxwA3MOsRmP6Sr5A8p7La7lgbdtHU~1CprgMb1mepa78kdQSsAU49n08JfL-mzs68b8pyMMJvxuTevYSGR5BLN7FLnp6HIwANdZWncy5haRgLz0CqPpxPKtWp7My~G1i5bhtu9LsYTon2GjD9ZaFOtCpUeDS-HeGoGKwxeEq6tmM-2eTnPxUYOAhP9M5rA35ohNKaqRAhJDw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         pic:'https://s3-alpha-sig.figma.com/img/40b7/c131/faedfd7f834c47ba118f3895ffc519cd?Expires=1683504000&Signature=eCGpM-WN~39zNM5~kkTLfWtQSEtLN-iogR--jPpkQNbiDoBnZ694f8X9XWo9aoXcRU3kUBH8oCaEv02KQKMM1a12PcFbUOP4rtFY8xFTX5~E3zJ~Rsf7Y21N7xWRBkWepWIPw-93bQJ2KDW0wTBaC8r60i4qyRPg0RkKQXKf1mbLu-GCY65atZY7yKQDdC0Wzc~vd2lIe00D4iWLO0E6EpIcGg1roZBNzWL5onok1f7QlwFJ-0cRV8~5S1QRqeLVFJrPZLfTTNh9MNWmMFn18nPxeOd0lO0cYGswDkiBgVDK5nsXvsynq4Om3YI60xqU7~7juNQn6jSph1hG~ssI5A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         date:'August 20, 2022',
    //         user:'Praveen kumar'
       
       
    //     },
    //     {
    //         key:1,
    //         name:'The Impact of Technology on the Workplace: How Technology is Changing asdjs asjkdandhj jknjknijb kdsjafjeh dsjvqwbnas wqbhjf nacs qhbwfj bansmfbqej asnbh',
    //         tag:'Technology',
    //         img:'https://s3-alpha-sig.figma.com/img/d420/949f/d83fa0b11500655074c36b4845f6b20c?Expires=1683504000&Signature=W7dx-Mu-tki5YAupOpm4UuJzfvC6Z872JuNCPuPU8P8JN-JbRVLjsRHHRfaIUhdHCiJxugZONS72zglp0q-L2gFV~Of04D9dzf1UGwgP5Rw9Fm0WeaeH9mgdlh~rKUo~8x6iGbOKjRnxwA3MOsRmP6Sr5A8p7La7lgbdtHU~1CprgMb1mepa78kdQSsAU49n08JfL-mzs68b8pyMMJvxuTevYSGR5BLN7FLnp6HIwANdZWncy5haRgLz0CqPpxPKtWp7My~G1i5bhtu9LsYTon2GjD9ZaFOtCpUeDS-HeGoGKwxeEq6tmM-2eTnPxUYOAhP9M5rA35ohNKaqRAhJDw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         pic:'https://s3-alpha-sig.figma.com/img/40b7/c131/faedfd7f834c47ba118f3895ffc519cd?Expires=1683504000&Signature=eCGpM-WN~39zNM5~kkTLfWtQSEtLN-iogR--jPpkQNbiDoBnZ694f8X9XWo9aoXcRU3kUBH8oCaEv02KQKMM1a12PcFbUOP4rtFY8xFTX5~E3zJ~Rsf7Y21N7xWRBkWepWIPw-93bQJ2KDW0wTBaC8r60i4qyRPg0RkKQXKf1mbLu-GCY65atZY7yKQDdC0Wzc~vd2lIe00D4iWLO0E6EpIcGg1roZBNzWL5onok1f7QlwFJ-0cRV8~5S1QRqeLVFJrPZLfTTNh9MNWmMFn18nPxeOd0lO0cYGswDkiBgVDK5nsXvsynq4Om3YI60xqU7~7juNQn6jSph1hG~ssI5A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
    //         date:'August 20, 2022',
    //         user:'Praveen kumar'
       
       
    //     }
    
    
    // ]


    const Obj=allBlogs?.allBlogs?.map((ele,index)=>{
    
   return  {
        id:ele.id,
        name:ele.title,
        tag:ele.tag,
        img:ele.locationPath,
           pic:ele?.userProfile,
           date:ele.updatedAt,
           user:ele.userName,
           views:ele.totalViews,
           index:index,
           privilege:ele?.privilege

    }
     })


    const handlePageChange = page => {
        console.log('anill',page)
        if(page!=currentPage){
            setPageLoader(true);
           }
        setCurrentPage(page);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    useEffect(()=>{
        share!==''?setIsModalOpen(true):setIsModalOpen(false)
      },[share])

    const copyLink=()=> {
        var copyText = document.getElementById("link");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        
        var tooltip = document.getElementById("Tooltip");
        tooltip.innerHTML = "Copied: " + copyText.value;
      }
      
      const removeLink=()=>  {
        var tooltip = document.getElementById("Tooltip");
        tooltip.innerHTML = "Copy to clipboard";
      } 
  return (
    <div   style={{
        background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
    
        }}>
    <div style={{padding:'40px 8vw',minHeight:'100vh'}}>
   
           
        <Row align='center' className='top-div-wrapper'>
      <div  className='top-div'>

      <Back className='back-btn' onClick={handleBackBtn} />
                <JobSearch 
                    suffix={<SearchOutlined style={{marginRight:'10px',fontSize:'16px'}} />} 
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={search}
                    style={{height:'45px',padding:'8px'}}
                  
                />
      </div>
      
                <div style={{'display':'flex',gap:'10px',justifyContent:'center',alignItems:'center' , height: '48px',flexDirection:"row"}}>
                <a href='https://testlogin.brigosha.com' style={{width:'103px'}} target="_blank" rel="noopener noreferrer">
                    <img alt='blogs' src={pen}/>
                    <span>Write Blog</span></a>
                </div>
            </Row>
            <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px'}}>
          
           <Heading >
            {/* <div className='gradient7'>Blogs<div className='polygon2'></div></div> */}
           <div className='highlight'>Blogs</div>
           </Heading></div>
           
           
           <span style={{'color':'rgba(24, 26, 42, 1)',fontSize:'24px',lineHeight:'28px',textAlign:'left',marginBottom:'32px',fontWeight:'700'}}>Newly Blogs</span>
          


{pageLoader?<div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center'}}>
           
           <LoadingOutlined style={{fontSize: "3em"}}/>
                </div> :
            Obj?.length==0?<div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center',height:'200px'}}> 
              
                <span><b>Uh!Uh! No Blogs Found!</b></span>
                <span><b>But you can find your way back by clicking on the homepage</b></span>
            </div>:<>
            
            <div style={{width:'100%',display:'flex',flexDirection:'column',marginTop:'20px'}}>
           
          
            <Row  style={{'display':'grid','gridTemplateColumns':'repeat(auto-fill, minmax(396px, 1fr))',gap:'10px','gridRowGap':'30px',placeItems:'center'}} className='blog-cards' >
           
            {Obj?.map((ele,key)=>{
                console.log('anil',Obj[key])
       return  <Col  className='team-card' id={key} >
       <Card {...Obj[key]} setShare={setShare} />
      </Col>
         })}
                
            </Row>
            <Row justify="space-between"  style={{marginTop:'40px'}} >
            
            <Col span={24}>
                <Pagination total={allBlogs?.meta?.totalItems} pageSize={24} length={allBlogs?.meta?.itemCount} currentPage={currentPage} showTotal onPageChange={(page) =>   handlePageChange(page)}/>
            </Col>
        </Row>
            </div>
            </>
           }
           
           <Modal  visible={isModalOpen} footer={null} closable={true} centered onCancel={handleCancel} style={{'zIndex':'100'}}   bodyStyle={{
        backgroundColor: '#F5F5F5'
    }}  destroyOnClose={true} >
       <ModelDiv>
            <div   style={{ backgroundColor: '#FFF',padding:'20px'}} >
         <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px',}}>
           <div className='event-head' style={{'width':'60vw'}}>
             
       
            <h1 className='title heading'  style={{color:'#000',fontSize:'26px',lineHeight:'40px',fontWeight:'600'}}>{Obj?.[share]?.name}</h1>
          
          

            <div style={{'display':'flex',flexDirection:'column',justifyContent:'flex-start'}}>
               <div style={{'display':'flex',alignItems:'center',gap:'10px',flexDirection:'row',marginBottom:'10px'}}>
               {Obj?.[share]?.pic &&  Obj?.[share]?.privilege=='Blogger'?<img alt='Software Development' src={Obj?.[share]?.pic} style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img alt='lidar' src={BrigoshaLogo} style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>
                }
        
                {/* <span>{Obj?.[share]?.user}</span> */}
                <span>{Obj?.[share]?.privilege=='SuperAdmin' || Obj?.[share]?.privilege=="Admin" || !Obj?.[share]?.privilege?"Brigosha Team":Obj?.[share]?.user}</span>
                <div>
                {Obj?.[share]?.date}
               </div>
               </div>
             
               <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'scroll hidden'}} className='blog-tags'> {
                   Obj?.[share]?.tag?.map((tag,index)=>{
                    return       index<3 ? <span style={{'color':'#fff',fontSize:'14px',lineHeight:'24px',backgroundColor:'rgba(75, 107, 251, 1)',display:'inline',padding:'0px 5px',height:'28px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500'}}>{tag}</span>:null

                })
                }
                 {
                  Obj?.[share]?.tag?.length>3?
                   <span style={{fontSize:'12px',lineHeight:'20px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{Obj?.[share]?.tag?.length-3}</span>
                   : null
                }
           
           </div>
         </div>
            </div>
           </div>
           <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',}}>
          
                   
           <div  style={{'height':'250px',width:'60vw',backgroundColor:'#fff',borderRadius:'12px',position:'relative',display:'flex',justifyContent:'center'}}> 
         
                    {/* <div style={{width:'26.5px',height:'26.5px', position:'absolute',right:10,top:20,background:'rgb(0,0,0,0.6)'}}>
                      <img  src={shareImg}  style={{width:'26.5px',height:'26.5px'}}/>
                    </div> */}
                    <img alt='Software Testing' src={Obj?.[share]?.img} style={{'height':'250px',width:'100%',borderRadius:'5px',objectFit: 'contain'}}/>
                 
              
                </div>
                   
          </div>
          </div>
         <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'column'}}>
                    <h1>Share this with your social community</h1>
                    <div style={{width:'100%',display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                  
                        <a href={` https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(`http://new.brigosha.com/our-story/blog/blogId=${Obj?.[share]?.id}`)}&title=${encodeURI(Obj?.[share]?.name)}&source=LinkedIn`}>      <img alt='automation' src={linkedIn} style={{width:'30px',height:'30px',cursor:'pointer'}}/></a>
                       
                        <a href={`https://twitter.com/intent/tweet?text=${encodeURI(Obj?.[share]?.name)}&url=${encodeURI(`http://new.brigosha.com/our-story/blog/blogId=${Obj?.[share]?.id}`)}`}  > <img src={twitter}   style={{width:'30px',height:'30px',cursor:'pointer'}}/></a>

                    </div>
                  
           </div>
        
          
        <div style={{width:'100%',padding:'10px',display:'flex',flexDirection:'column',justifyContent:'center ',alignItems:'center'}}>
                        
        <div style={{width:'95%',display:'flex',justifyContent:'space-between',alignItems:'center',height:"50px"}}>
        <span style={{color:'rgba(173, 173, 173, 1)',fontWeight:'700'}}>or copy link</span>      
                            </div>
                        <div style={{width:'95%',display:'flex',justifyContent:'space-between',alignItems:'center',height:"50px",background:'rgba(238, 238, 238, 1)',padding:'3px 16px'}}>
                             <input style={{'color':'rgba(173, 173, 173, 1)',border:'none',background:'rgba(238, 238, 238, 1)',width:'80%',padding:'3px', outline: 'none'}} id='link' value={`http://new.brigosha.com/our-story/blog/blogId=${ Obj?.[share]?.id}`} />
                           
                            <div class="tooltip">
<button onClick={copyLink} onMouseOut={removeLink} style={{'color':'rgb(0, 37, 70)',border:'none',fontWeight:'700',cursor:'pointer'}}>
  <span class="tooltiptext" id="Tooltip">Copy to clipboard</span>
  Copy Link
  </button>
</div>
                        </div>
                    </div>
         </ModelDiv>
   </Modal>   
        
   
    </div>
    </div>
  )
}



const Card = ({ name, img ,tag,user,pic,date,id,setShare,views,index,privilege=''}) => {

    const navigate = useNavigate();
    const handleShare=(event)=>{
        event.stopPropagation();
       
        const link=`http://new.brigosha.com/our-story/blog/blogId=${id}`;
       
       setShare(index);
    }
    return(
    
        <CardStyle  key={index} className='blog-card' >
            <div className='card-wrapper' onClick={()=>{  document.body.scrollTop = 0; // For Safari
       document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       navigate(`/our-story/blog/blogId=${id}`)}} >
            
            <div style={{display:'flex',flexDirection:'column',width:'100%'}} >
                <div style={{width:'100%',maxHeight:'241px',position:'relative',display:'flex',justifyContent:'center'}}>
                    {/* <div style={{width:'26.5px',height:'26.5px', position:'absolute',right:10,top:20,zIndex:'20'}} className='share_blog'>
                      <img  src={shareImg}  style={{width:'26.5px',height:'26.5px',cursor:'pointer'}} onClick={(e)=>handleShare(e)} />
                    </div> */}
                <img src={img} alt='img'  style={{objectFit: 'cover'}} className='blogImg'/>
                {/* <div className="view_blog" style={{width:'50px',height:'26.5px',position:'absolute',right:10,bottom:20,display:'flex',alignItems:'center',gap:'5px',alignItems:'center',gap:'5px',background:'rgb(0,0,0,0.6)',justifyContent:'center'}}>
                      <img  src={eye}  style={{width:'17.22px',height:'11.47px'}}/>
                      <span style={{color:'#fff',fontSize:'11px'}}>{views}</span>
                    </div> */}
                </div>
         <div className='info'>
            
            <div className='event-head'>
                <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'hidden',height:'30px',width:'100%',overflowX:'auto',alignItems:'center',flexWrap:'wrap'}} className='blog-tags'>
                {
                    tag?.map((tag,index)=>{
                        if(index<3)
                       return <span style={{'color':'rgba(75, 107, 251, 1)',fontSize:'12px',lineHeight:'20px',backgroundColor:'rgba(75, 107, 251, 0.05)',display:'inline',padding:'0px 5px',height:'20px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>{tag}</span>
                  
                    })
                }
                {
                   tag?.length>3?
                   <span style={{fontSize:'12px',lineHeight:'20px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{tag?.length-2}</span>
                   : null
                }
                
           
           </div>
            <h1 className='title heading'  style={{color:'#000',cursor:'pointer',marginTop:'10px' }} >{name}</h1>
            </div>
          

            <div style={{'display':'flex',justifyContent:'space-between',alignItems:'center'}} className='blogcard-footer'>
               <div style={{'display':'flex',justifyContent:'flex-start',alignItems:'center',gap:'6px',flexDirection:'row',flex:1}}>
                
               {pic && ( privilege=='Blogger')?<img alt='ceo' src={pic} style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img alt='Industrial IoT Solutions' src={BrigoshaLogo} style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>}
                 
              <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
           
                {/* <span>{user}</span> */}
                <span>{privilege=='SuperAdmin' || privilege=='Admin'|| !privilege?"Brigosha Team":user}</span>
                <div>
               
                <span>{date}</span>
                <span> | </span>
                <span>{views} Views</span>
                </div>
                </div>
              
            
               </div>
               <div className='share_blog' style={{display:'flex',justifyContent:'flex-end',}}>
                      <img  src={shareImg}  style={{width:'26.5px',height:'26.5px',cursor:'pointer'}} onClick={(e)=>handleShare(e)} />
                    </div>
               <div>
             
               </div>
            </div>
            </div>
            </div>
            </div>
        </CardStyle>
    )
}


const CardStyle = styled.div`
  
    margin-bottom: 10px;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
  

   
   .card-wrapper{
   
    
    cursor:pointer;
    background-color: #fff;
    justify-content:center;
    padding: 20px;
    position: relative;
    margin:0 auto 30px;
    display:flex;
    justify-content:center;
  
    border-radius:12px;
    border:1px solid #E8E8EA;
    max-height:420px;
   z-index:1;
   
  
   }
   .blog-tags::-webkit-scrollbar {
    height: 3px !important; /* width of the entire scrollbar */
    width:3px !important;
  }
  .blog-tags::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /*roundness of the scroll thumb*/
    /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
  }

   .glass-button {
    display: inline-block;
    padding: 12px 22px;
    height:50px;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color:#fff;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .glass-button:hover {
    background-color: rgba(255,255,255,0.2);
  }
   .event-head {
    width:360px;
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items:flex-start;
    text-align: center;
    opacity:1;
    padding: 6px;
  
  }
    &:hover{
        transform: scale(1.02);
    }

    .blogImg{
       
        min-height:241px;
        height:241px;
        width:360px;
        border-radius:8px;
        
       
    }
   

    .title{
        max-height:50px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        text-align:left;
        display:flex;
        align-items:center;
      
        margin-bottom:0px !important;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }

    @media screen and (max-width: 770px){
        .card-wrapper{
            padding:5px;
        }
        .event-head{
            width:100%;
        }
        .title{
            font-size: calc(1vw + 6px);
            width:100%;
            max-height:40px;
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .info{
            // position:absolute;
            // bottom:10px;
            margin-top:10px;
        }
        .blogImg{
         max-height:140px;
            min-height:117px;
            width:195px;
            margin-top:8px;
           
        }
        .blogcard-footer{
            font-size:12px;
            padding:5px;
        }
        .blogcard-footer img{
            width:20px !important;
            height:20px !important;
        }
    }
   
`

const ModelDiv = styled.div`
   
   max-height:722px;
  min-height:300px;
  max-width:943px;


  background:#F5F5F5;
   
  .title{
    height:40px;
    font-size: 14px;
    font-weight: 600;
  
    margin-bottom:10px !important;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.blog-tags::-webkit-scrollbar {
    height: 5px !important; /* width of the entire scrollbar */
    width:3px !important;
  }
  .blog-tags::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /*roundness of the scroll thumb*/
    /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
  }


    
 @media  screen and (max-width: 950px){

    min-height:300px;
    max-height:max-content;
  
    

}
`



const mapStateToProps = state => {
    const {
        allBlogs
    }  = state.we;
  
    return {allBlogs}
  }
  
  const mapDispatchToProps = dispatch =>  ({
    fetchBlogs: (params,successCallback) => dispatch(fetchBlogs(params,successCallback)),
   
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(AllBlogs)
  


