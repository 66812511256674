import React, { useState, useEffect } from 'react';
import { NavbarContents,SubNavbarContents } from './style';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import CompanyLogo from './brigosha-logo-new.svg';
import { Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import SubNavbar from './SecondaryNavbar/subNavbar';
import { connect } from 'react-redux';
import { updateMainMenu } from './redux/action';
import { routes } from '../../roots/routes';
import Hamburger from './hamburger.svg'

const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.scrollTo(0, 0);
}

const NavLink = ({ to, className, activeClassName, inactiveClassName, exact,elem, ...rest }) => {
    let location = useLocation();
    let routeMatches = matchRoutes(routes, location);
    let isActive;
   
    console.log('navbar',location.pathname,to,elem,exact) 


    if(exact){
       
        isActive = location.pathname===to;
      
     if(location.pathname.includes(elem?.toLowerCase().split(' ').join('-'))){
       isActive=true;
        
     }
    }
    else{
       
        isActive = routeMatches.some((match) => match.pathname === to)
       
    }

 



    let allClassNames = className + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`);
 
    return <Link to={to} className={allClassNames} { ...rest }/>
}

const PrimaryNavbar = ({ pathName, updateMainMenu }) => {
    const [sidemenu, setSidemenu] = useState(false);
    let location = useLocation();
    useEffect(() => {
      
        updateMainMenu( window.location.pathname.startsWith('/technology')?'technology':'we') 
       
    },[location])
//scroll effect
const [yOffset, setYOffset] = useState(window.pageYOffset);
const [visible, setVisible] = useState(true);

useEffect(() => {
  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);
});

useEffect(()=>{
    if(sidemenu){
        document.body.style.overflow='hidden';
    }else {
        document.body.style.overflow='auto';
    }
})


function handleScroll() {
  const currentYOffset = window.pageYOffset;
  const visible = yOffset > currentYOffset;

  setYOffset(currentYOffset);
  setVisible(visible);
}
  
  
   
 
    return(
        <>
        <NavbarContents 
           style={{'height':'79px',zIndex:'5' , position:'sticky',filter: visible ?null:'drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.08))'}}
            className='content'
        
        >
            <Row className='Menu'  style={{ borderBottom: visible ? '1px solid #EEEEEE':'none'}}>
                <Col 
                    className='header-nav-icon' 
                    xs={12} sm = {12} md={16} lg={16} xl={16} xxl={16}
                >
                   
                    <Link to = '/' onClick={scrollToTop}>
                        <img 
                            src = {CompanyLogo} 
                            alt = 'brigosha'
                           
                            className='header-company-icon'
                            onClick={() => updateMainMenu('we')} 
                        />
                    </Link>
                    <div className='hamburger-icon' onClick={() => setSidemenu(true)}>
                        <img src={Hamburger} alt='brigosha'/>
                    </div>
                </Col>
                <Col 
                    xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}
                    className='menu-content' 
                >
                <ul className='navbar-links content'>
                    <li> 
                        <NavLink 
                        to = '/' 
                        className = 'navTabs secondary'
                        activeClassName = 'active'
                        inactiveClassName= 'inactive'
                        onClick={() => {
                            updateMainMenu('we')
                            scrollToTop()
                        }}
                        >   
                            We
                        </NavLink>  

                    </li>
                    {/* <li>
                        <NavLink 
                        to = '/iot' 
                        className='navTabs secondary'
                        activeClassName = 'active'
                        inactiveClassName= 'inactive'
                        onClick={() => {
                            updateMainMenu('iot')
                            scrollToTop()
                        }}
                        > 
                         iot
                        </NavLink>

                    </li> */}
                    {/* <li>
                        <NavLink 
                        to = '/automotive' 
                        className='navTabs secondary'
                        activeClassName = 'active'
                        inactiveClassName= 'inactive'
                        onClick={() => {
                            updateMainMenu('automotive')
                            scrollToTop()
                        }}
                        > 
                            Automotive
                        </NavLink> 
                    </li> */}
                    <li>
                        <NavLink 
                        to = '/technology' 
                        className='navTabs secondary'
                        activeClassName = 'active'
                        inactiveClassName= 'inactive'
                        onClick={() => {
                            updateMainMenu('technology')
                            scrollToTop()
                        }}
                        > 
                            Technology
                        </NavLink> 
                    </li>
                </ul>
                </Col>
            </Row>
           

            <div className={`${sidemenu ? 'sidemenu active' : 'sidemenu'}`} >
                <div 
                    onClick={() => setSidemenu(false)}
                    className='sidemenu-close-btn'
                >
                    <CloseOutlined style={{fontSize: 'calc(2vw + 10px)', color: '#0086FF'}}/>
                </div>
                <ul 
                    className='sidemenu-options content'
                    style={{
                        
                    }}
                >
                    <li 
                        onClick={() => {
                            setSidemenu(false)
                            scrollToTop()
                        }}
                    >
                        <NavLink className='options' activeClassName='primary' to='/' >We</NavLink>
                    </li>
                    <li style={{padding: 0}}>
                        <ul 
                            className='sidemenu-suboptions grey'
                            style={{
                                
                            }}
                        >
                            <li 
                                onClick={() => {
                                    setSidemenu(false)
                                    scrollToTop()
                                }}
                            >
                                <NavLink className='grey' activeClassName='primary' to='/' exact={true} elem="Our Story">Our Story</NavLink>
                            </li>
                            <li 
                                onClick={() => {
                                    setSidemenu(false)
                                    scrollToTop()
                                }}
                            >
                                <NavLink className='grey' activeClassName='primary' to='/teams'>Teams</NavLink>
                            </li>
                            <li 
                                onClick={() => {
                                    setSidemenu(false)
                                    scrollToTop()
                                }}
                            >
                                <NavLink className='grey' activeClassName='primary' to='/join-us'>Join Us</NavLink>
                            </li>
                            <li 
                                onClick={() => {
                                    setSidemenu(false)
                                    scrollToTop()
                                }}
                            >
                                <NavLink className='grey' activeClassName='primary' to='/get-in-touch'>Get In Touch</NavLink>
                            </li>
                        </ul>
                    </li>
                    {/* <li 
                        onClick={() => {
                            setSidemenu(false)
                            scrollToTop()
                        }}
                    >
                        <NavLink className='options' activeClassName='primary' to='/automotive'>Automotive</NavLink>
                    </li>
                    <li 
                        onClick={() => {
                            setSidemenu(false)
                            scrollToTop()
                        }}
                    >
                        <NavLink className='options' activeClassName='primary' to='/iot'>IoT</NavLink>
                    </li> */}
                     <li 
                        onClick={() => {
                            setSidemenu(false)
                            scrollToTop()
                        }}
                    >
                        <NavLink className='options' activeClassName='primary' to='/technology' >Technology</NavLink>
                    </li>
                    <li style={{padding: 0}}>
                        <ul 
                            className='sidemenu-suboptions grey'
                            style={{
                                
                            }}
                        >
                            <li 
                                onClick={() => {
                                    setSidemenu(false)
                                    scrollToTop()
                                }}
                            >
                                <NavLink className='grey' activeClassName='primary' to='/technology' exact={true} elem='Mobility Solutions'>Mobility Solutions</NavLink>
                            </li>
                            <li 
                                onClick={() => {
                                    setSidemenu(false)
                                    scrollToTop()
                                }}
                            >
                                <NavLink className='grey' activeClassName='primary' to='/technology/connected-solutions' elem='Connected Solutions' exact={true} >Connected Solutions</NavLink>
                            </li>
                          
                          
                        </ul>
                    </li>
                    <li 
                        onClick={() => {
                            setSidemenu(false)
                            scrollToTop()
                        }}
                    >
                        <a className='options' href='https://newpmt.brigosha.com' target="_blank" rel="noopener noreferrer">Associate Portal</a>
                    </li>
                </ul>
            </div>
             
        </NavbarContents>
        <SubNavbarContents
     
          style={{'height':'49px',position:'sticky',zIndex:'4',top:visible?'79px':'-49px',transition:'top 0.3s', filter: visible ?'drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.08))':null}}
          className='content '>
         <Row className='subMenu' >
         <Col span={24}   >
             <SubNavbar   label={pathName} data={SubMenu[pathName]} NavLink={NavLink} scrollToTop={scrollToTop} />
         </Col> 
     </Row>
     </SubNavbarContents>
     </>
    )
}

// const SideMenu = () => {
//     return(

//     )
// }

const SubMenu = {
    'we': ['Our Story', 'Teams', 'Join Us', 'Get In Touch'],

    'technology':['Mobility Solutions','Connected Solutions']
}

const mapStateToProps = state => {
    const {
        pathName
    }  = state.menu;

    return {pathName}
}

const mapDispatchToProps =dispatch =>  ({
    updateMainMenu: (val) => dispatch(updateMainMenu(val))
})


export default connect(mapStateToProps, mapDispatchToProps)(PrimaryNavbar);