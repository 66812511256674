import React, { useEffect, useState } from 'react';
import {createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Layout } from 'antd';
import 'antd/dist/antd.min.css';
import { GlobalStyles } from './Global.styles';
import { PrimaryNavbar } from './components';
import { Footer } from './components/';
import { Routes, Route, useRoutes } from 'react-router-dom';
import rootReducer from './rootReducer';
import { Provider } from 'react-redux';
import { routes } from './roots/routes';
import { saveToLocalStorage, loadFromLocalStorage } from "./store";
import { useLocation } from 'react-router-dom';
// import loading from './loading.gif'
const { Content } = Layout;

const persistedState = loadFromLocalStorage();

export const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk)
)

store.subscribe(() => saveToLocalStorage(store.getState()));

window.store = store;

const App = () => {
  let element = useRoutes(routes)
  const [ sidebar, setSidebar ] = useState(false);
  const [ spinner, setSpinner ] = useState(true);

  
  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000)
    if (history.scrollRestoration) {
      history.scrollRestoration = 'auto';
    } else {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
           
        }
    }
    
    
  }, [])

  // useEffect(() => {
  //   const handlePageLoaded = () => {
  //     setSpinner(false);
  //   };

  //   window.onload = handlePageLoaded;

  //   return () => {
  //     window.onload = null;
  //   };
  // }, []);

  const { pathname } = useLocation();

 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
  useEffect(() => {
    var btn = $('#button');
    btn.on('click', function(e) {
      e.preventDefault();
      window.scrollTo(0, 0);
    });
    const handleScroll = () => {
      if (window.scrollY > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);
  const handleDoubleClick=()=>{
    window.open('https://newpmt.brigosha.com', '_blank');
  }






  
  return (
//     spinner?<div  style={{'height':'100vh','display':'flex',justifyContent:'center','alignItems':'center'}}>
//     <img src={loading} width={150} height={150}/>
// </div> :
    <Provider store={store}>
      <Layout style={{backgroundColor: '#FFFFFF'}}>
        <GlobalStyles />
        <PrimaryNavbar routes={routes}/>
        <Content  >
          {element}
        </Content>
        <Footer />
        <a id="button"  onDoubleClick={handleDoubleClick}></a>
      </Layout>
    </Provider>
  )
}

export default App